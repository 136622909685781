import Vue from 'vue';

// eslint-disable-next-line no-unused-vars
export default function openDialog({ priceList }) {
  const vm = new Vue({
    data() {
      return {
        show: true,
        beforeCancel: () => {
          this.$destroy();
        },
        columns: [
          {
            label: '时段',
            scopedSlots: {
              default: ({ row }) => {
                const { priceStartPeriod, priceEndPeriod, defaultFlag } = row;
                if (String(defaultFlag) === '1') {
                  return '其余时段';
                }
                const parse = id => {
                  id = Number(id);
                  const index = id - 1;
                  const hour = Math.floor(index / 2);
                  const minute = (index % 2) * 30;
                  const name = `${`${hour}`.padStart(2, '0')}:${`${minute}`.padStart(2, '0')}`;
                  return name;
                };
                return `${parse(priceStartPeriod)}~${parse(priceEndPeriod)}`;
              },
            },
          },
          {
            label: '电费',
            scopedSlots: {
              default: ({ row: { powerRate } }) => `${powerRate}元/度`,
            },
          },
          {
            label: '服务费',
            scopedSlots: {
              default: ({ row: { serviceRate } }) => `${serviceRate}元/度`,
            },
          },
        ],
      };
    },
    beforeDestroy() {
      this.show = false;
      document.body.removeChild(this.$el);
    },
    render() {
      const { show, columns } = this;
      const title = `价格详情`;
      return (
        <YkcOperationDialog
          show={show}
          title={title}
          showCancelButton={false}
          beforeCancel={this.beforeCancel}
          vOn:close-dialog={this.beforeCancel}>
          <template slot="content">
            {/* 由于jsx限制，这里需要显式通过props传递 */}
            <ykc-table data={priceList || []} columns={columns}></ykc-table>
          </template>
        </YkcOperationDialog>
      );
    },
  });
  vm.$mount();
  document.body.appendChild(vm.$el);
}
